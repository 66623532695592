
import { Component, Mixins } from 'vue-property-decorator';
import HeaderInfosMixin from '../header-infos';
import RunDocMixin from '@/mixins/data-operations/doc/run-doc-mixin';
import { apiToStorageRuns } from '@/store/modules/easy-firestore/api-to-storage-runs';

@Component
export default class StorageToStorageRunsItemView extends Mixins(HeaderInfosMixin, RunDocMixin) {
	moduleName: string = apiToStorageRuns.moduleName;

	get itemTabsItems(): any {
		if (Object.keys(this.item).length === 0) return [];
		return [
			this.runDetailsTab,
			this.runLogsTab,
			this.configurationTab,
			this.fullJSONTab,
			this.otherRunsTab,
			this.notesTab,
		];
	}

	get runDetailsData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: true,
					viewId: this.item.id,
					viewType: 'run',
					runStatus: this.item.status,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Run Details',
					tooltip: true,
					description: 'Details of the Storage to Storage run',
					paramItems: [
						{ id: 'account', label: 'Account', value: this.item.account },
						{ id: 'environment', label: 'Environment', value: this.item.environment },
						{
							id: 'client_type',
							label: 'Client Type',
							value: this.getFormattedClientType(this.item.configuration_context.client_type),
						},
						{
							id: 'client_version',
							label: 'Client Version',
							value: this.getFormattedClientVersion(this.item.configuration_context.client_version),
						},
						{
							id: 'configuration_id',
							label: 'Configuration Id',
							value: this.item.configuration_context.configuration_id,
						},
						{
							id: 'configuration_type',
							label: 'Configuration Type',
							value: this.item.configuration_context.configuration_type,
						},
						{ id: 'job_id', label: 'Job Id', value: this.item.job_id },
						{
							id: 'dag_execution_date',
							label: 'Execution Date',
							value: this.$moment(this.item.dag_execution_date, '').format('YYYY/MM/DD - HH:mm:ss'),
						},
						{ id: 'duration', label: 'Duration', value: this.item.duration?.split('.')[0] },
						{ id: 'dag_run_id', label: 'Run Id', value: this.item.dag_run_id },
						{ id: 'pubsub_message_id', label: 'Message Id', value: this.item.pubsub_message_id },
						{ id: 'output_filename', label: 'Output Filename', value: this.item.output_filename },
					],
				},
			},
		];
	}

	get configurationData() {
		return [
			{
				component: 'view-header',
				props: {
					item: this.item,
					collection: this.moduleName,
					activeHeader: false,
					viewId: this.item.configuration_id,
					viewType: 'conf',
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Context',
					tooltip: true,
					description: 'Context of the Api to Storage configuration',
					paramItems: [
						{
							id: 'configuration_type',
							label: 'Configuration Type',
							value: this.item.configuration_context.configuration_type,
						},
						{
							id: 'account',
							label: 'Account',
							value: this.item.account,
						},
						{
							id: 'environment',
							label: 'Environment',
							value: this.item.environment,
						},
					],
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'Source API',
					description: 'Information about the Source API receiving the data payload',
					columns: [
						{
							label: 'Type',
							field: 'configuration_context.source.type',
							width: '100px',
						},
						{
							label: 'Project ID',
							field: 'configuration_context.source.gcp_project_id',
							width: '200px',
						},
						{
							label: 'Topic',
							field: 'configuration_context.pubsub_topic',
							width: '200px',
						},
					],
					rows: [this.item],
					vflexLength: 'xs10',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-table',
				props: {
					tableTitle: 'GCS Destinations',
					description: 'Multi GCS Destinations for API Payload received',
					columns: [
						{ label: 'Type', field: 'type', width: '100px' },
						{ label: 'Destination Bucket', field: 'gcs_destination_bucket', width: '100px' },
						{ label: 'Destination Folder', field: 'gcs_destination_prefix', width: '100px' },
						{ label: 'File Name Template', field: 'gcs_filename_template', width: '100px' },
					],
					rows: [...this.item.configuration_context.destinations],
					vflexLength: 'xs10',
					lineNumbers: false,
					searchOptionsEnabled: false,
				},
			},
			{
				component: 'parameters-list',
				props: {
					groupTitle: 'Update information',
					paramItems: [
						{
							id: 'updated_date',
							label: 'Updated date',
							value: this.$moment(
								this.item.configuration_context.update_date || this.item.configuration_context.updated_date,
								''
							).format('YYYY/MM/DD - HH:mm:ss'),
						},
						{
							id: 'updated_by',
							label: 'Updated by',
							value: this.item.configuration_context.updated_by,
						},
					],
				},
			},
		];
	}
}
